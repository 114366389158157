<template>
  <div class="h-auto w-screen p-4">
    <br />
    <textarea
      id="jsonSettings"
      class="my-5 w-screen max-w-xl p-3 text-base rounded-lg shadow-lg border-black"
      style="min-height: 650px"
      v-model="JSONData"
    />
    <br />
    <json-editor v-model="JSONData" />
    <vs-button @click="save"> Save </vs-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import JsonEditor from "vue-json-edit";
export default {
  data() {
    return {
      JSONData: "",
    };
  },
  components: {
    JsonEditor,
  },
  props: ["slug"],
  methods: {
    ...mapActions("eCommerce", ["saveJsonSettings", "getJsonSettings"]),
    save() {
      const value = JSON.parse(this.JSONData);
      const slug = this.slug;
      this.saveJsonSettings({ value, slug })
        .then((val) => {
          this.$vs.notify({
            title: "Success",
            text: "Settings Saved.",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed to save settings.",
            text: "Please try again later.",
            color: "danger",
          });
          console.error(err);
        });
    },
  },
  async created() {
    this.getJsonSettings({ slug: this.slug }).then((val) => {
      this.JSONData = JSON.stringify(val.data.data, null, 4);
    });
  },
};
</script>

<style lang="scss">
.el-input {
  width: 400px;
}
</style>
